import * as React from "react";

import { IContextProps } from "./IContextProps";
import { IContextSelectionProps } from "./IContextSelectionProps";
import { IListProps } from "../../../../controls/list/IListProps";
import { List } from "../../../../controls/list/List";
import { Modal } from "react-bootstrap";
import { Localizer } from "../../../../localization/Localizer";

/**
 * The 'ContextSelectionView' component.
 * @class
 */
export class ContextSelectionView extends React.Component<IContextSelectionProps, {}> {
    /**
     * Renders the component.
     * @method
     * @returns {React.ReactElement<IContextSelectionProps>} An instance of a React element representing the component.
     */
    public render(): React.ReactElement<IContextSelectionProps> {

        const listProps: IListProps<IContextProps> = {
            items: this.props.contexts,
            renderRow: (item: IContextProps) => this.renderContextRow(item)
        };

        return (
            <Modal show={this.props.isDialogVisible} onHide={() => { }} className="context-selection">
                <Modal.Body>
                    <p className="context-selection-message">{Localizer.text("ContextSelectionInfoMessageText")}</p>
                    <List className={"contexts"} {...listProps}></List>
                    <p className="context-selection-message">{Localizer.text("ContextSelectionInfoSuggestionText")}</p>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Renders the context row.
     * @method
     * @returns {React.ReactFragment} The context row.
     */
    private renderContextRow(props: IContextProps): React.ReactFragment {

        return (
            <div className="context-row">
                <p className="context-type">{props.type}</p>
                <a className="context-name" href={props.href}>{props.name}</a>
            </div>
        );
    }
}