/**
 * Component providing the login experience.
 * @class
 */

import * as React from "react";

import { AuthenticationAccountType } from "../../services/authentication/AuthenticationAccountType";
import { ILoginPageProps } from "./ILoginPageProps";
import { Localizer } from "../../localization/Localizer";

import Button = require("react-bootstrap/lib/Button");


export class LoginPage extends React.Component<ILoginPageProps, any> {
    /**
     * Render this component.
     * @method
     * @returns {React.ReactElement<any>} An instance of a React element representing the component.
     */
    public render(): React.ReactElement<ILoginPageProps> {
        return (
            <div className="login-page">
                <div className="page-content">
                    <h1 className="consent-header">{this.props.consentHeader}</h1>
                    <p className="consent-body" dangerouslySetInnerHTML={{ __html: this.props.consentBody }} />
                    <div className="buttons-section">
                        <Button onClick={() => this.props.onAccountTypeSelect(AuthenticationAccountType.MSEmployee)}>{Localizer.text("MicrosoftEmployeeLogin")}</Button>
                    </div>
                </div>
            </div>
        );
    }
}
