import * as React from "react";

import { Card } from "../../../cards/Card";
import { ExchangeDetails } from "../../../../model/finance/ExchangeDetails";
import { IFinanceSearchResultsCardProps } from "./IFinanceSearchResultsCardProps";
import { IListProps } from "../../../../controls/list/IListProps";
import { List } from "../../../../controls/list/List";

/**
 * The 'FinanceSearchResultsCard' component.
 * @class
 */
export class FinanceSearchResultsCard extends React.Component<IFinanceSearchResultsCardProps, {}> {
    /**
     * Renders the component.
     * @method
     * @returns {React.ReactElement<IFinanceSearchProps>} An instance of a React element representing the component.
     */
    public render(): React.ReactElement<IFinanceSearchResultsCardProps> {

        const listProps: IListProps<ExchangeDetails> = {
            items: this.props.exchanges,
            renderRow: (item: ExchangeDetails) => this.renderExchangeResultRow(item)
        };

        return (
            <Card className="finance-search-results-card" size={this.props.cardSize}>
                <div className="search-results-section">
                    <h1>Exchanges</h1>
                    <List className={"search-results"} {...listProps}></List>
                </div>
            </Card>
        );
    }

    /**
     * Renders the exchange result row.
     * @method
     * @returns {ExchangeDetails} exchangeDetails The exchange details.
     */
    private renderExchangeResultRow(details: ExchangeDetails): React.ReactFragment {

        const secondLineComponents = [];
        secondLineComponents.push(details.shortName);
        secondLineComponents.push(`Mic: ${details.mic}`);
        secondLineComponents.push(`Delay: ${details.delay}`);

        const secondLineText = secondLineComponents.filter(c => !!c).join(" / ")

        return (
            <div className="result-row">
                <a>{details.displayName}</a>
                <p>{secondLineText}</p>
            </div>
        );
    }
}