import * as React from "react";

import { AsyncButton } from "../../../../controls/asyncButton/AsyncButton";
import { Card } from "../../../cards/Card";
import { DropdownGroup } from "../../../../controls/dropdownGroup/DropdownGroup";
import { IFinanceSearchProps } from "./IFinanceSearchProps";
import { TextBoxGroup } from "../../../../controls/textBoxGroup/TextBoxGroup";

/**
 * The 'FinanceSearchCard' component.
 * @class
 */
export class FinanceSearchCard extends React.Component<IFinanceSearchProps, {}> {
    /**
     * Renders the component.
     * @method
     * @returns {React.ReactElement<IFinanceSearchProps>} An instance of a React element representing the component.
     */
    public render(): React.ReactElement<IFinanceSearchProps> {

        return (
            <Card
                className="finance-search-card"
                size={this.props.cardSize}
                header={{ title: "Exchanges and Instruments search" }}>
                <TextBoxGroup className="pe-code" {...this.props.peCode} />
                <TextBoxGroup className="file-code" {...this.props.fileCode} />
                <DropdownGroup className="search-type" {...this.props.searchType} />
                <TextBoxGroup className="search-text" {...this.props.searchText} />
                <AsyncButton className="search-btn" {...this.props.searchButton}>Search</AsyncButton>
            </Card>
        );
    }
}